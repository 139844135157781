import { View } from '@components-teammove';
import styled from 'styled-components';

export const EmptyContainer = styled(View)`
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 18px;
  height: 400px;
  justify-content: center;
  display: flex;
  color: ${({ theme }) => theme.textColor};
`;