import { Worker } from '@react-pdf-viewer/core';
import * as pdfjsLib from 'pdfjs-dist';
import React from 'react';

const PDFViewer = ({ url, onDocumentLoad, children }) => {
  // Definindo explicitamente a versão do worker para corresponder à versão instalada no package.json (2.6.347)
  // Isso resolve o erro de incompatibilidade entre a API (2.16.105) e o Worker (2.6.347)
  React.useEffect(() => {
    // Garantindo que a versão global do worker seja a mesma que a versão instalada
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';
  }, []);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      {children}
    </Worker>
  );
};

export default PDFViewer;
