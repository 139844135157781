import { Empty } from '@components-teammove';
import React, { useEffect } from 'react';
import { EmptyContainer } from './styles';

const PDFViewer = ({ url, onDocumentLoad }) => {
  useEffect(() => {
    if (!onDocumentLoad) return;
    onDocumentLoad();
  }, [onDocumentLoad]);

  if (!url) {
    return (
      <EmptyContainer>
        <Empty />
      </EmptyContainer>
    );
  }

  return (
    <embed src={url} width="100%" height="1200px" type="application/pdf" />
  );
};

export default PDFViewer;
